import SeoMeta from 'components/Basic/Head/SeoMeta';
import CommonLayout from 'components/Layout/CommonLayout';
import ErrorLayout from 'components/Layout/ErrorLayout';
import Webline from 'components/Layout/Webline/Webline';
import {
    ErrorPageButtonLinkStyled,
    ErrorPageImageStyled,
    ErrorPageStyled,
    ErrorPageTextHeadingStyled,
    ErrorPageTextMainStyled,
    ErrorPageTextStyled,
} from 'components/Pages/ErrorPage/ErrorPage.style';
import { useGtmStaticPageView } from 'hooks/gtm/useGtmStaticPageView';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useGtmStaticPageViewEvent } from 'utils/Gtm/EventFactories';

type Error404Props = {
    withCommonLayout?: boolean;
};

const Error404: FC<Error404Props> = ({ withCommonLayout }) => {
    const t = useTypedTranslationFunction();
    const pageTitle = t('Something wrong happened... Page not found "404"');

    const gtmStaticPageViewEvent = useGtmStaticPageViewEvent('404');
    useGtmStaticPageView(gtmStaticPageViewEvent);

    const ErrorComponent = (
        <ErrorLayout>
            <Webline>
                <ErrorPageStyled>
                    <ErrorPageTextStyled>
                        <ErrorPageTextHeadingStyled>
                            {t('We have nothing to show you at this url.')}
                        </ErrorPageTextHeadingStyled>
                        <ErrorPageTextMainStyled>
                            {t('But at other addresses we have a lot for you...')}
                        </ErrorPageTextMainStyled>
                        <ErrorPageButtonLinkStyled href="/" isButton>
                            {t('Back to shop')}
                        </ErrorPageButtonLinkStyled>
                    </ErrorPageTextStyled>
                    <ErrorPageImageStyled>
                        <picture>
                            <source media="(max-width: 768px)" srcSet="/public/frontend/images/404_m.png" />
                            <source srcSet="/public/frontend/images/404_desktop_2x.jpg 2x, /public/frontend/images/404_desktop.jpg 1x" />
                            <img loading="lazy" data-src="/public/frontend/images/404_desktop.jpg 1x" alt={t('404')} />
                        </picture>
                    </ErrorPageImageStyled>
                </ErrorPageStyled>
            </Webline>
        </ErrorLayout>
    );

    if (withCommonLayout) {
        return <CommonLayout title={pageTitle}>{ErrorComponent}</CommonLayout>;
    }

    return (
        <>
            <SeoMeta title={pageTitle} />
            {ErrorComponent}
        </>
    );
};

export default Error404;
