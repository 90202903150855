import Error404 from 'components/Pages/ErrorPage/404';
import { Error500 } from 'components/Pages/ErrorPage/500/Error500';
import { logException } from 'helpers/errors/logException';
import { initServerSideProps, ServerSidePropsType } from 'helpers/InitServerSideProps';
import { getServerSidePropsWrapper } from 'helpers/serverSide/getServerSidePropsWrapper';
import { ErrorProps } from 'next/error';
import { ReactElement } from 'react';
import { NextPageExtended } from 'types/global';

const MIDDLEWARE_STATUS_CODE_KEY = 'middleware-status-code';
const MIDDLEWARE_STATUS_MESSAGE_KEY = 'middleware-status-message';
type ErrorPageProps = ErrorProps & {
    hasGetInitialPropsRun: boolean;
    statusCode: number;
    props: Partial<ServerSidePropsType> | Promise<Partial<ServerSidePropsType>>;
    err: string;
};
const ErrorPage: NextPageExtended<ErrorPageProps> = ({ hasGetInitialPropsRun, err, statusCode }): ReactElement => {
    if (!hasGetInitialPropsRun && err) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        logException(err);
        // Flushing is not required in this case as it only happens on the client
    }

    return statusCode === 404 ? <Error404 /> : <Error500 />;
};

ErrorPage.layout = 'plain';

ErrorPage.getInitialProps = getServerSidePropsWrapper(({ redisClient, domainConfig, t }) => async (context: any) => {
    const middlewareStatusCode = Number.parseInt(context.res.getHeader(MIDDLEWARE_STATUS_CODE_KEY) || '');
    const middlewareStatusMessage = context.res.getHeader(MIDDLEWARE_STATUS_MESSAGE_KEY);

    const serverSideProps = await initServerSideProps({ context, redisClient, domainConfig, t });
    const statusCode = middlewareStatusCode || context.res.statusCode || 500;
    let err: string | Error = middlewareStatusMessage || context.err || 'Unknown error (inside _error.tsx)';

    if (err instanceof Error) {
        err = JSON.stringify({ name: err.name, message: err.message, stack: err.stack, cause: err.cause });
    }

    if (statusCode !== 404) {
        logException({
            message: err,
            statusCode,
            initServerSidePropsResonse: JSON.stringify(serverSideProps),
            location: 'ErrorPage.getInitialProps.noErrorDebugging',
        });
    }

    // eslint-disable-next-line require-atomic-updates
    context.res.statusCode = statusCode;
    const props: Partial<ServerSidePropsType> = 'props' in serverSideProps ? await serverSideProps.props : {};

    return {
        ...props,
        statusCode,
        err,
        hasGetInitialPropsRun: true,
    } as ErrorPageProps;
});

export default ErrorPage;
